import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CognitoIdentityProviderClient, DeleteUserCommand } from '@aws-sdk/client-cognito-identity-provider';
import { getCurrentUser, signOut, fetchAuthSession } from '@aws-amplify/auth';
import './UserSettings.css'; 

function UserSettings() {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchUserInfo();
    handleDeepLink();
  }, [location]);

  const handleDeepLink = () => {
    // Check for delete account deep link
    if (location.hash === '#delete-account') {
      setShowConfirmDialog(true);
      // Optionally scroll to the delete section
      const deleteSection = document.querySelector('.danger-zone');
      if (deleteSection) {
        deleteSection.scrollIntoView({ behavior: 'smooth' });
      }
      // Automatically trigger delete flow after a short delay
      setTimeout(() => {
        handleDeleteAccount();
      }, 500);
    }
  };

  const fetchUserInfo = async () => {
    try {
      const currentUser = await getCurrentUser();
      setUserInfo(currentUser);
      console.log('Current user:', currentUser); // For debugging
    } catch (err) {
      console.error('Error fetching user info:', err);
      setError('Failed to load user information');
    }
  };

  const handleDeleteAccount = async () => {
    setIsDeleting(true);
    setError(null);

    try {
      const { tokens } = await fetchAuthSession();
      const accessToken = tokens.accessToken.toString();

      const client = new CognitoIdentityProviderClient({ 
        region: 'eu-central-1'
      });

      await client.send(new DeleteUserCommand({
        AccessToken: accessToken
      }));

      localStorage.clear();
      sessionStorage.clear();
      await signOut({ global: true });
      navigate('/');
    } catch (err) {
      setError('Failed to delete account. Please try again later.');
      console.error('Delete account error details:', {
        message: err.message,
        code: err.code,
        name: err.name,
        stack: err.stack
      });
    } finally {
      setIsDeleting(false);
      setShowConfirmDialog(false);
    }
  };
  
  return (
    <div className="settings-container">
      <h2>Account Settings</h2>
      <div className="settings-content">
        <div className="settings-section">
          <h3>Profile Information</h3>
          {userInfo ? (
            <div className="user-info">
              <p><strong>User ID:</strong> {userInfo.userId}</p>
              <p><strong>Sign In Details:</strong> {userInfo.signInDetails?.loginId}</p>
            </div>
          ) : (
            <p>Loading user information...</p>
          )}
        </div>
        
        <div className="settings-section danger-zone">
          <h3>Danger Zone</h3>
          <div className="delete-account-section">
            <p>Once you delete your account, there is no going back. Please be certain.</p>
            
            {!showConfirmDialog ? (
              <button 
                className="delete-account-button"
                onClick={() => setShowConfirmDialog(true)}
              >
                Delete Account
              </button>
            ) : (
              <div className="confirm-delete-dialog">
                <p>Are you sure you want to delete your account?</p>
                <div className="confirm-delete-buttons">
                  <button 
                    className="confirm-delete-button"
                    onClick={handleDeleteAccount}
                    disabled={isDeleting}
                  >
                    {isDeleting ? 'Deleting...' : 'Yes, Delete My Account'}
                  </button>
                  <button 
                    className="cancel-delete-button"
                    onClick={() => setShowConfirmDialog(false)}
                    disabled={isDeleting}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
            
            {error && <p className="error-message">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserSettings;
