import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { LikedDance } from './models';
import { getCurrentUser } from '@aws-amplify/auth';
import { FaHeart } from 'react-icons/fa';
import './LikedDancesComponent.css';

function LikedDancesComponent() {
  const [likedDances, setLikedDances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [filteredDances, setFilteredDances] = useState([]);
  const [retryCount, setRetryCount] = useState(0);

  const shuffleArray = (array) => {
    // Create a copy of the array to avoid mutating the original
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      // Generate random index
      const j = Math.floor(Math.random() * (i + 1));
      // Swap elements
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const formatMessage = (dances) => {
    // Group dances by dance style
    const groupedDances = dances.reduce((acc, dance) => {
      if (!acc[dance.danceStyle]) {
        acc[dance.danceStyle] = [];
      }
      acc[dance.danceStyle].push(dance);
      return acc;
    }, {});
  
    // Format the message
    let message = "My Favorite Dances 💃🕺\n\n";
    
    Object.entries(groupedDances).forEach(([style, dances]) => {
      message += `${style}:\n`;
      dances.forEach(dance => {
        message += `• ${dance.title} - ${dance.artist}`;
        if (dance.songId) {
          message += ` (https://open.spotify.com/track/${dance.songId})`;
        }
        message += '\n';
      });
      message += '\n';
    });
  
    return encodeURIComponent(message);
  };
  
  const handleShare = async (dances) => {
    const message = formatMessage(dances); // you can keep using your existing message formatter
  
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'My Favorite Dances',
          text: decodeURIComponent(message),
          url: window.location.href
        });
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error sharing:', error);
          alert('Error sharing content');
        }
      }
    } else {
      alert('Web Share API is not supported in your browser');
    }
  };
  const openSingleTrackInSpotify = (dance) => {
    if (!dance.songId) {
      alert('No Spotify track available for this dance');
      return;
    }
  
    try {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  
      if (isMobile) {
        window.location.href = `https://open.spotify.com/track/${dance.songId}`;
      } else {
        window.location.href = `spotify:track:${dance.songId}`;
      }
    } catch (error) {
      console.error('Error opening Spotify:', error);
      alert('Unable to open Spotify. Please make sure Spotify is installed.');
    }
  };
  

  const openInSpotify = (dances) => {
    const validTracks = dances.filter(dance => dance.songId);
    
    if (validTracks.length === 0) {
      alert('No Spotify tracks available for the selected dances');
      return;
    }
  
    try { 
      if (validTracks.length === 1) {
          window.location.href = `spotify:track:${validTracks[0].songId}`;
    } else {
      // For multiple tracks
      const shuffledTracks = shuffleArray(validTracks);
      
      // For desktop, try the URI scheme
      const trackUris = shuffledTracks
        .map(dance => `spotify:track:${dance.songId}`)
        .join(':');
      window.location.href = `spotify:trackset:DanceScout:${trackUris}`;
    }
    } catch (error) {
      console.error('Error opening Spotify:', error);
      alert('Unable to open Spotify. Please make sure Spotify is installed.');
    }
  };

  useEffect(() => {
    
    // Subscribe to changes
    let subscription;
    const setupSubscription = async () => {
      try {
        const user = await getCurrentUser();
        //const userEmail = user.signInDetails.loginId;
        subscription = DataStore.observe(LikedDance, 
          dance => dance.userEmail.eq(user.userId)
        ).subscribe({
          next: ({ opType, element }) => {
              if (opType === 'INSERT') {
                setLikedDances(prev => [element, ...prev]);
              } else if (opType === 'DELETE') {
                setLikedDances(prev => prev.filter(d => d.id !== element.id));
              }
            }
        });
      } catch (error) {
        console.error('Error setting up subscription:', error);
      }
    };

    fetchLikedDances();
    setupSubscription();

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [retryCount]); // Add retryCount as dependency

  // Add new useEffect to handle filtering
  useEffect(() => {
    if (selectedStyles.length === 0) {
      setFilteredDances(likedDances);
    } else {
      const filtered = likedDances.filter(dance => 
        selectedStyles.includes(dance.danceStyle)
      );
      setFilteredDances(filtered);
    }
  }, [selectedStyles, likedDances]);

  // Get unique dance styles from liked dances
  const getDanceStyles = () => {
    const styles = [...new Set(likedDances.map(dance => dance.danceStyle))];
    return styles;
  };

  const handleStyleSelect = (style) => {
    setSelectedStyles(prev => {
      if (prev.includes(style)) {
        return prev.filter(s => s !== style);
      }
      return [...prev, style];
    });
  };

// Define fetchLikedDances outside useEffect
const fetchLikedDances = async () => {
  try {
    const user = await getCurrentUser();
    // Use loginId instead of username
    const userEmail = user.signInDetails.loginId;
    //console.log('Using email:', userEmail); // Debug log
    // Wait for DataStore to be ready
    await DataStore.start();
    
    const fetchedLikedDances = await DataStore.query(LikedDance, 
      c => c.userEmail.eq(userEmail
      )
    );
    
    //console.log('Fetched dances:', fetchedLikedDances); // Debug log
    
    // Sort by most recently added
    const sortedDances = fetchedLikedDances.sort((a, b) => 
      new Date(b.createdAt) - new Date(a.createdAt)
    );
    
    setLikedDances(sortedDances);
    setLoading(false);
  } catch (error) {
    console.error('Error fetching liked dances:', error);
    
    // Retry logic
    if (retryCount < 3) {
      setTimeout(() => {
        setRetryCount(prev => prev + 1);
      }, 1000);
    } else {
      setLoading(false);
    }
  }
};

  const handleUnlike = async (dance) => {
    try {
      await DataStore.delete(dance);
      setLikedDances(prev => prev.filter(d => d.id !== dance.id));
    } catch (error) {
      console.error('Error removing like:', error);
    }
  };

  // Add this function to check if we should show the Spotify button
  const isMobile = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return isMobile;
  };

  if (loading) {
    return <div className="loading">Loading your liked dances...</div>;
  }

  return (
    <div className="liked-dances-container">
      
      <div className="filter-section">
{/* Add the multi-select filter */}
<div className="dance-style-filter">
        {getDanceStyles().map(style => (
          <button
            key={style}
            className={`filter-button ${selectedStyles.includes(style) ? 'active' : ''}`}
            onClick={() => handleStyleSelect(style)}
          >
            {style}
          </button>
        ))}
      </div>

      {filteredDances.length > 0 && isMobile() && (

    <div className="share-button-container">
      <button 
      className="share-button"
      onClick={() => handleShare(filteredDances)}
    >
      <svg 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <path 
          d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z" 
          fill="currentColor"
        />
        <path 
          d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z" 
          fill="currentColor"
        />
        <path 
          d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z" 
          fill="currentColor"
        />
        <path 
          d="M8.59 13.51L15.42 17.49M15.41 6.51L8.59 10.49" 
          stroke="currentColor" 
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        />
      </svg>
    </button></div>
  )}
  </div>

      
      {filteredDances.length > 0 && !isMobile() && (
        <button 
          className="spotify-button-all"
          onClick={() => openInSpotify(filteredDances)}
        >
          <span role="img" aria-label="music">🎵</span> 
          Open {filteredDances.length} {filteredDances.length === 1 ? 'Song' : 'Songs'} in
          <img 
            src="/spotify/Spotify_Full_Logo_RGB_Black.png"
            alt="Spotify"
            className="spotify-logo-all"
          />
        </button>
      )}

      {filteredDances.length === 0 ? (
        <p>No dances match your selected filters!</p>
      ) : (
        <div className="liked-dances-list">
          {filteredDances.map((dance) => (
  <div key={dance.id} className="liked-dance-item">
    <div className="dance-info">
      <h2>{dance.title}</h2>
      <p>Artist: {dance.artist}</p>
      <p>Dance: {dance.danceStyle}</p>
    </div>
    <div className="button-container">
      {dance.songId && (
        <button 
          className="spotify-button-small"
          onClick={() => openSingleTrackInSpotify(dance)}
          aria-label={`Play ${dance.title} on Spotify`}
        >
          <img 
            src="/spotify/Spotify_Full_Logo_RGB_Black.png"
            alt="Play on Spotify"
          />
        </button>
      )}
      <button 
        onClick={() => handleUnlike(dance)}
        className="unlike-button"
        aria-label="Unlike dance"
      >
        <FaHeart />
      </button>
    </div>
  </div>
))}

        </div>
      )}
    </div>
  );
}

export default LikedDancesComponent;
